<template>
  <v-expand-transition>
    <v-col cols="12" class="pt-0 mb-3" v-show="show_legend">
      <v-divider class="mb-4"/>
      <v-row>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="icono" left size="22">mdi-clock-outline</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">C.P:</span> -->
            Inicio de la Jornada
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="red" left size="22">mdi-clock-check-outline</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">C.V:</span> -->
            Fin de la Jornada
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="primary" left size="22">mdi-alarm-check</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">C.A:</span> -->
            Duración de la Jornada
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="black" left size="22">mdi-briefcase-clock-outline</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">CAJAS:</span> -->
            Promedio de minutos en Cliente
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="teal" left size="22">mdi-account-tie-outline</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">PFV:</span> -->
            Clientes Activados
          </span>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="d-flex align-center">
          <v-icon color="primary" left size="22">mdi-text-box-check-outline</v-icon>
          <span class="blue-grey--text text-h6 text-capitalize">
            <!-- <span class="font-weight-bold">PFV:</span> -->
            Total de Visitas
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-expand-transition>
</template>
<script>
export default {
  name: 'Leyenda',
  props:{
    value: Boolean
  },
  data(){
    return {
      show_legend: this.value,
    }
  },
  watch:{
    value(val, oldVal) {
      if(val === oldVal) return

      this.show_legend = val;
    },
    show_legend(val, oldVal) {
       if(val === oldVal) return

       this.$emit('input', val);
    }
  }
}
</script>
