<template>
  <v-expand-transition>
    <v-col cols="12" class="pt-0 mb-3" v-show="show_legend">
      <v-divider class="mb-4"/>
      <v-row justify="center">
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="icono" left size="16">mdi-camera</v-icon>
          <span class="blue-grey--text text-h6">          
            Indicador de Imagenes 
          </span>
        </v-col>   
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="icono" left size="16">mdi-text-box-check-outline</v-icon>
          <span class="blue-grey--text text-h6">
            
            Indicador de Pedidos
          </span>
        </v-col>   
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="icono" left size="16">mdi-map-marker-radius-outline</v-icon>
          <span class="blue-grey--text text-h6">            
            Indicador de Geolocalización
          </span>
        </v-col>   
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="icono" size="16">mdi-map-marker</v-icon>
          <span class="blue-grey--text text-h6">            
           <span class="icono--text font-weight-bold mr-2"> D.R:</span> Visita Dentro del Rango permitido
          </span>
        </v-col>   
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="warning" size="16">mdi-map-marker</v-icon>
          <span class="blue-grey--text text-h6">            
            <span class="warning--text font-weight-bold mr-2">S.R:</span>Visita Supera el Rango permitido
          </span>
        </v-col>   
        <v-col cols="auto" class="d-flex align-center">
          <v-icon color="red" size="16">mdi-map-marker</v-icon>
          <span class="blue-grey--text text-h6">            
            <span class="red--text font-weight-bold mr-2">F.L:</span> Visita Fuera del Lugar del Cliente
          </span>
        </v-col>   
      </v-row>
    </v-col>
  </v-expand-transition>
</template>
<script>
export default {
  name: 'Leenda',
  props:{
    value: Boolean
  },
  data(){
    return {
      show_legend: this.value,
    }
  },
  watch:{
    value(val, oldVal) {
      if(val === oldVal) return 

      this.show_legend = val;
    },
    show_legend(val, oldVal) { 
       if(val === oldVal) return 

       this.$emit('input', val);
    }
  }
}
</script>